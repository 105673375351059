

.catalog-list .catalog-item {
  width: 50%;
  height: 50vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.catalog-list .catalog-item:nth-child(2n) {
  border-left: 2px solid #f0f0f0;
}
.catalog-list .catalog-item:nth-child(n + 3) {
  border-top: 2px solid #f0f0f0;
}
